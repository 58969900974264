import styles from "./css/AuthHeader.module.css";
import { useRouter } from "next/router";
import {
  ButtonTheme,
  ButtonWithText,
  FontClass,
  ResponsiveContainer,
} from "ui";
import Link from "next/link";
import { isFromNative } from "@web/utils/env/isFromNative";

type Props = Record<string, never>;

type ButtonGroupProps = Record<string, never>;

function ButtonGroup(_props: ButtonGroupProps) {
  const router = useRouter();
  const fromNative = isFromNative();
  return (
    <div className={styles.buttonGroup}>
      <ButtonWithText
        buttonTheme={ButtonTheme.Highlight}
        disabled={false}
        fontClass={FontClass.Body1Medium}
        onClick={() =>
          router.push(
            fromNative
              ? router.pathname.includes("login")
                ? "/native/register"
                : "/native/login"
              : router.pathname.includes("login")
              ? "/register"
              : "/login"
          )
        }
        type="button"
      >
        {router.pathname.includes("login") ? "Get Started" : "Sign In"}
      </ButtonWithText>
    </div>
  );
}

/**
 * Header for the login and registration pages.
 */
export default function AuthHeader(_props: Props) {
  return (
    <div className={styles.container}>
      <ResponsiveContainer>
        <div className={styles.header}>
          <Link href="/">
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              className={styles.sokiLogo}
              src="/images/harken-logo-purple.svg"
              alt="Logo"
            />
          </Link>
          <ButtonGroup />
        </div>
      </ResponsiveContainer>
    </div>
  );
}
