import { MaybeUndef } from "shared";
import { PageWithHeaderAndFooterProps as Props } from "@ui/components/containers/PageWithHeaderAndFooter";
import { Footer } from "@web/components/footers/Footer";
import { Header } from "@web/components/headers/Header";
import { PageWithHeaderAndFooter as PageWithHeaderAndFooterUi } from "ui";

export function PageWithHeaderAndFooter({
  children,
  hasBottomTabs = false,
  header,
  footer,
}: Omit<Props, "header" | "footer"> & {
  footer?: MaybeUndef<JSX.Element>;
  header?: MaybeUndef<JSX.Element>;
}): JSX.Element {
  return (
    <PageWithHeaderAndFooterUi
      header={header === undefined ? <Header /> : header}
      footer={footer === undefined ? <Footer /> : footer}
      hasBottomTabs={hasBottomTabs}
    >
      {children}
    </PageWithHeaderAndFooterUi>
  );
}
