import {
  ButtonWithText,
  TextInput,
  FlexBox,
  ButtonTheme,
  FontClass,
  notifyError,
  Body3Medium,
  ColorClass,
  PersonIcon,
  ColorValue,
  IconAndLabel,
} from "ui";
import { useState } from "react";
import invariant from "tiny-invariant";
import { firebaseLogin } from "@web/utils/firebase/auth/firebaseLogin";
import {
  postLoginEmail,
  ResponseBody as LoginEmailResponseBody,
} from "@web/utils/rest/login/postLoginEmail";
import { isValidUsername, Maybe } from "shared";
import {
  postLoginGoogle,
  ResponseBody as LoginGoogleResponseBody,
} from "@web/utils/rest/login/postLoginGoogle";
import { useViewerContext } from "@web/hooks/contexts/useViewerContext";
import { padUrlParamsWithToken as padUrlParamsWithToken } from "@web/utils/auth/PadUrlParamsWithToken";

type Props = {
  createAccountNonce: Maybe<string>;
  googleIdToken: Maybe<string>;
};

export function RegisterCreateAccount({
  createAccountNonce,
  googleIdToken,
}: Props) {
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setIsLoggingIn } = useViewerContext();

  const loginCommonCallback = async (
    responseBody: Maybe<LoginEmailResponseBody | LoginGoogleResponseBody>
  ) => {
    if (responseBody == null) {
      setIsLoading(false);
      notifyError();
      return;
    }

    if (responseBody.firebaseToken != null) {
      try {
        setIsLoggingIn(true);
        await firebaseLogin(responseBody.firebaseToken);
      } catch (e) {
        setIsLoggingIn(false);
        notifyError((e as Error).message);
        setIsLoading(false);
        return;
      }
    }

    if (responseBody.redirect != null) {
      window.location.href = padUrlParamsWithToken(responseBody);
      return;
    }

    invariant(responseBody.errorDescription != null);
    setIsLoading(false);
    notifyError(responseBody.errorDescription, responseBody.errorMessage);
  };

  const loginEmailCallback = async (createAccountNonceInner: string) => {
    setIsLoading(true);
    const data = await postLoginEmail({
      createAccountNonce: createAccountNonceInner,
      username,
    });

    return loginCommonCallback(data);
  };

  const loginGoogleCallback = async (googleIdTokenInner: string) => {
    setIsLoading(true);
    const data = await postLoginGoogle({
      idToken: googleIdTokenInner,
      username,
    });

    return loginCommonCallback(data);
  };

  return (
    <FlexBox alignItems="center" flexDirection="column" gap={24}>
      <TextInput
        label={
          <IconAndLabel
            icon={<PersonIcon colorValue={ColorValue.Secondary} />}
            label={
              <Body3Medium
                colorClass={ColorClass.Secondary}
                textTransform="uppercase"
              >
                Username
              </Body3Medium>
            }
          />
        }
        onChange={setUsername}
        placeholder="Enter your desired username"
        value={username}
      />
      <ButtonWithText
        buttonTheme={ButtonTheme.Highlight}
        disabled={!isValidUsername(username)}
        fontClass={FontClass.Body1}
        isLoading={isLoading}
        onClick={async () => {
          invariant(createAccountNonce != null || googleIdToken != null);
          if (createAccountNonce != null) {
            await loginEmailCallback(createAccountNonce);
          } else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            await loginGoogleCallback(googleIdToken!);
          }
        }}
        style={{ width: "100%" }}
      >
        Create Account
      </ButtonWithText>
    </FlexBox>
  );
}
