import { notifyError } from "ui";
import { NotifyErrorDescription } from "@web/types/enums/NotifyErrorDescription";
import { firebaseLogout } from "@web/utils/firebase/auth/firebaseLogout";
import { postLogout } from "@web/utils/rest/login/postLogout";
import { isFromNative } from "../env/isFromNative";

export async function logout() {
  const result = await postLogout();
  const fromNative = isFromNative();
  if (result == null || !result.success) {
    notifyError(NotifyErrorDescription.UnexpectedLogoutError);
    return;
  }

  await firebaseLogout();

  window.location.href = fromNative ? "/native/login" : "/login";
}
