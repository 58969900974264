import { isFromNative } from "@web/utils/env/isFromNative";
import { ResponseBody } from "@web/utils/rest/login/postLoginEmail";

export function padUrlParamsWithToken(responseBody: ResponseBody): string {
  let baseUrl = responseBody.redirect
    ? responseBody.redirect
    : window.location.href;
  const harkenToken = responseBody.harkenLoginToken
    ? responseBody.harkenLoginToken
    : "";

  const fromNative = isFromNative();
  if (!fromNative) {
    return baseUrl;
  }

  let wasRelative = false;
  if (!baseUrl.startsWith("http://") && !baseUrl.startsWith("https://")) {
    baseUrl = `http://www.example.com${baseUrl}`;
    wasRelative = true;
  }

  const url = new URL(baseUrl);
  // Merge existing and new parameters
  if (harkenToken) {
    url.searchParams.set("harkenNativeLoginToken", harkenToken);
  }

  let finalUrl = url.toString();
  if (wasRelative) {
    finalUrl = finalUrl.replace("http://www.example.com", "");
  }
  return finalUrl;
}
