import {
  getFirebaseAnalytics,
  getFirebaseAuth,
} from "@web/utils/firebase/initializeFirebase";
import { signInWithCustomToken } from "firebase/auth";
import { setUserId } from "firebase/analytics";

declare const window: any;

export async function firebaseLogin(token: string): Promise<void> {
  const userCredential = await signInWithCustomToken(getFirebaseAuth(), token);
  if (userCredential.user) {
    setUserId(getFirebaseAnalytics(), userCredential.user.uid);
    try {
      window.ReactNativeWebview.postMessage(
        JSON.stringify({ userid: userCredential.user.uid })
      );
    } catch (e) {}
  } else {
    try {
      window.ReactNativeWebview.postMessage(JSON.stringify({ userid: null }));
    } catch (e) {}
  }
}
