import axios from "axios";
import { Maybe } from "shared";
import { consoleLogErrorIfNotProd } from "@web/utils/analytics/consoleLogErrorIfNotProd";
import { getRestUrl } from "@web/utils/env/getRestUrl";

type RequestBody = {
  createAccountNonce?: string;
  email?: string;
  emailNonce?: string;
  username?: string;
};

export type ResponseBody = {
  errorDescription?: string;
  errorMessage?: string;
  firebaseToken?: string;
  harkenLoginToken?: string;
  redirect?: string;
  success: boolean;
};

export async function postLoginEmail(
  requestBody: RequestBody
): Promise<Maybe<ResponseBody>> {
  try {
    const { data } = await axios.post<ResponseBody>(
      getRestUrl("login/email"),
      requestBody,
      {
        // Needed so session cookie gets set
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    consoleLogErrorIfNotProd(e as Error);
    return null;
  }
}
